import React, { useState, useEffect } from "react";
import Control from './control'
import { AiOutlineYoutube } from "react-icons/ai";
import Loading from '../../Loading';
export default function Check() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState()

    useEffect(() => {
        setIsLoading(true)
        const token = JSON.parse(localStorage.getItem("user-token")).token
        setToken(token)
        console.log("🌵💜🐢", token)

        //https://likya.api.polyline.works/getVideos
        fetch('https://likya.api.polyline.works/getVideos', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'x-access-token': token,
            },
        })
            .then(response => response.json())
            .then(json => setData(json))
            .catch(error => alert(error));
        setIsLoading(false)
    }, []);
    console.log("🌵💜🐢", data)



    return (
        <React.Fragment>
            {
                isLoading ? (
                    <Loading />
                ) : (
                    <div className="container max-w-3xl px-4 mx-auto sm:px-8">
                        <div className="py-8">
                            <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                                <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                                    Video
                                                </th>
                                                <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                                    status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.items && data.items.map((video) => {
                                                return (
                                                    <tr key={video.id.videoId}>
                                                        <td>
                                                            <img src={video.snippet.thumbnails.medium.url} alt="a" />
                                                        </td>
                                                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                                            <div className="flex items-center">
                                                                <div className="ml-3">
                                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                                        {video.snippet.title}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <Control key={video.id.videoId} title={video.snippet.title} id={video.id.videoId} thumbnail={video.snippet.thumbnails.medium.url} />
                                                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                                            <a href={`https://www.youtube.com/watch?v=${video.id.videoId}&ab_channel=metintüzün`} rel="noopener noreferrer" target="_blank" className="text-2xl text-red-600 hover:text-red-900">
                                                                <AiOutlineYoutube />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </React.Fragment>
    )
}
