import React, { useState, useEffect } from 'react';
import Loading from '../../../Loading';
import Modal from "react-modal";
import { AiOutlineCloudUpload, AiOutlineCloseCircle } from "react-icons/ai";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'


Modal.setAppElement("#root");


export default function UpdateModal(props) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [order, setOrder] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState()

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token")).token
        setToken(token)
    }, [])

    const handleSubmit = (e) => {
        console.log("submit");
        e.preventDefault();
        update()
        toggleModal();
    }

    async function update() {
        setIsLoading(true);
        let item = { title, description, order };
        let result = await fetch(`https://likya.api.polyline.works/updateInfoMt/${props.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'x-access-token': token,
            },
            body: JSON.stringify(item)
        });
        result = await result.json();
        JSON.stringify(result)
        setIsLoading(false);
    }

    useEffect(() => {
        fetch(`https://likya.api.polyline.works/getInfoMt/${props.id}`)
            .then(response => response.json())
            .then(data => {
                setTitle(data?.title)
                setDescription(data?.description)
                setOrder(data?.order)

            })
    }, [props.id]);

    return (
        <React.Fragment>
            {
                isLoading ? (
                    <Loading />
                ) : (
                    <div className="flex justify-center items-center w-full">
                        <button className={props.class} onClick={toggleModal}>{props.title}</button>
                        <Modal
                            isOpen={isOpen}
                            onRequestClose={toggleModal}
                            style={{
                                content: {
                                },
                            }}
                        >
                            <button onClick={toggleModal} className="text-red-900 float-right" >
                                <AiOutlineCloseCircle className="w-6 h-6 text-red-900" />
                            </button>
                            <div className="flex  justify-center  w-full ">
                                {/* Fuck the world, think about your country  */}
                                <div className="">
                                    <div className="text-center">
                                        <h2 className="mt-5   text-3xl font-bold text-gray-900">
                                            Hakkında Düzenleme
                                        </h2>
                                    </div>
                                    <form className="mt-8 space-y-3" onSubmit={handleSubmit} >

                                        <div className="grid grid-cols-1 space-y-2">
                                            <label className="text-sm font-bold text-gray-500 tracking-wide">Başlık</label>
                                            <input className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="Blog Başlığı"
                                                name="title" value={title} onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                        <span className=" flex flex-col px-2 w-96" >
                                            <label className="text-sm font-bold text-gray-500 tracking-wide">Sıra</label>
                                            <input className="text-base  p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="number" placeholder="Sıra"
                                                name="order" value={order} onChange={(e) => setOrder(parseInt(e.target.value))}
                                            />
                                        </span>
                                        <div className="grid grid-cols-1 space-y-2">
                                            <label className="text-sm font-bold text-gray-500 tracking-wide">Detay</label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={description}

                                                name="description"
                                                value={description}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setDescription(data);
                                                }}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 space-y-2">
                                            <button type="submit" className="my-5 w-full flex justify-center bg-blue-500 text-gray-100 p-4  rounded-full tracking-wide
                        font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-600 shadow-lg cursor-pointer transition ease-in duration-300">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    </div>
                )
            }
        </React.Fragment>

    );
}
