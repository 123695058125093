import React, { useState, useEffect } from 'react';
import Modal from "react-modal";
import { AiOutlineCloudUpload, AiOutlineCloseCircle } from "react-icons/ai";

import { useParams } from 'react-router-dom';

export default function AddModal(props) {
    const [title, setTitle] = useState('');
    const [imagesTitle, setImagesTitle] = useState('')
    const [picturesTitle, setPicturesTitle] = useState('')
    const [order, setOrder] = useState('');
    const [contentsType] = useState('IMAGE');
    const [isOpen, setIsOpen] = useState(false);
    const [previewSource, setPreviewSource] = useState('');
    const [picturesSource, setPicturesSource] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState()

    let { id } = useParams();

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    const handelFileInput = (e) => {
        const file = e.target.files[0];
        previewFile(file);
    }
    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setPreviewSource(reader.result);
        }
    }
    const handlePictures = (e) => {
        const file = e.target.files[0];
        picturesFile(file);
    }
    const picturesFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setPicturesSource(reader.result);
        }
    }
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token")).token
        setToken(token)
    }, [])

    const handleSubmit = (e) => {
        console.log("submit");
        e.preventDefault();
        if (!previewSource) return;
        if (!picturesSource) return;
        uploadImage(previewSource, picturesSource, id)
        toggleModal();
    }

    const uploadImage = async (images, pictures, id) => {
        setIsLoading(true);
        let stageID = id;
        let item = { images, pictures, imagesTitle, picturesTitle, title, contentsType, stageID, order };
        //https://likya.api.polyline.works
        await fetch("https://likya.api.polyline.works/createContents", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-access-token': token,
            },
            body: JSON.stringify(item)
        })
        console.log(item);
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            {
                isLoading ? (
                    <h1>Ekleniyor</h1>
                ) : (
                    <div className="flex items-center justify-center w-full">
                        <button className={props.class} onClick={toggleModal}>{props.title}</button>
                        <Modal
                            isOpen={isOpen}
                            onRequestClose={toggleModal}
                            style={{
                                content: {
                                },
                            }}
                        >
                            <button onClick={toggleModal} className="float-right text-red-900" >
                                <AiOutlineCloseCircle className="w-6 h-6 text-red-900" />
                            </button>
                            <div className="flex justify-center w-full ">
                                {/* Fuck the world, think about your country  */}
                                <div className="">
                                    <div className="text-center">
                                        <h2 className="mt-5 text-3xl font-bold text-gray-900">
                                            Resim Ekleme
                                        </h2>
                                        <p className="flex justify-center mt-2 text-sm text-gray-400 ">
                                            {previewSource && (<img src={previewSource} alt="chosen" style={{ height: '100px' }} />)}
                                            {picturesSource && (<img src={picturesSource} alt="chosen" style={{ height: '100px' }} />)}
                                        </p>
                                    </div>
                                    <form className="mt-8 space-y-3" onSubmit={handleSubmit} >
                                        <div className="grid grid-cols-1 space-y-2">
                                            <label className="text-sm font-bold tracking-wide text-gray-500">Resim</label>
                                            <div className='flex'>
                                                <div>
                                                    <div className="flex items-center justify-center w-full">
                                                        <label className="flex flex-col w-full p-10 text-center border-4 border-dashed rounded-lg h-60 group">
                                                            <div className="flex flex-col items-center justify-center w-full h-full text-center ">
                                                                <div className="flex justify-center flex-auto w-2/5 mx-auto -mt-10 max-h-48">
                                                                    <AiOutlineCloudUpload className="object-center w-1/2 h-36" />
                                                                </div>
                                                                <p className="pointer-none text-gray-500 font-bold "> 332 X 256</p>

                                                                <p className="text-gray-500 pointer-none "> Tıklayınız...</p>
                                                            </div>
                                                            <input type="file" className="hidden"
                                                                name="banner"
                                                                onChange={handelFileInput}
                                                            />
                                                        </label>
                                                    </div>
                                                    <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="Resim Başlığı"
                                                        name="title" onChange={(e) => setImagesTitle(e.target.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="flex items-center justify-center w-full">
                                                        <label className="flex flex-col w-full p-10 text-center border-4 border-dashed rounded-lg h-60 group">
                                                            <div className="flex flex-col items-center justify-center w-full h-full text-center ">
                                                                <div className="flex justify-center flex-auto w-2/5 mx-auto -mt-10 max-h-48">
                                                                    <AiOutlineCloudUpload className="object-center w-1/2 h-36" />
                                                                </div>
                                                                <p className="text-gray-500 pointer-none "> Tıklayınız...</p>
                                                            </div>
                                                            <input type="file" className="hidden"
                                                                name="banner"
                                                                onChange={handlePictures}
                                                            />
                                                        </label>
                                                    </div>
                                                    <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="2.Resim Başlığı"
                                                        name="title" onChange={(e) => setPicturesTitle(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <p className="text-sm text-gray-300">
                                            <span>File type: png,jpg,jpeg</span>
                                        </p>
                                        <div className="grid grid-cols-1 space-y-2">
                                            <div className="grid grid-cols-1 space-y-2">
                                                <label className="text-sm font-bold tracking-wide text-gray-500">Başlık</label>
                                                <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="Başlık"
                                                    name="title" onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </div>
                                            <div className="grid grid-cols-1 space-y-2">
                                                <span className="flex flex-col w-96" >
                                                    <label className="text-sm font-bold tracking-wide text-gray-500">Sıra</label>
                                                    <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="number" placeholder="Sıra"
                                                        name="order" onChange={(e) => setOrder(parseInt(e.target.value))}
                                                    />
                                                </span>
                                            </div>
                                            <button type="submit" className="flex justify-center w-full p-4 my-5 font-semibold tracking-wide text-gray-100 transition duration-300 ease-in bg-blue-500 rounded-full shadow-lg cursor-pointer focus:outline-none focus:shadow-outline hover:bg-blue-600">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal >
                    </div >
                )
            }
        </React.Fragment >
    );
}