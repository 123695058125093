import React from "react";
import { Player } from '@lottiefiles/react-lottie-player';

export default function Loading() {

    return (
        <div className="loadingCtn">
            <Player
                autoplay
                loop
                src="https://assets1.lottiefiles.com/private_files/lf30_d4dpd0uk.json"                
            />
        </div>
    )
}

