import React from "react";
import Links from "./Links";
import { useHistory } from 'react-router-dom'
import { RiLoginCircleLine } from "react-icons/ri";
const jwt = require('jsonwebtoken');


export default function Navbar() {
    const history = useHistory();
    const token = JSON.parse(localStorage.getItem("user-token")).token
    let decode = jwt.decode(token)
    let profileImage = decode.image
    function logout() {
        localStorage.removeItem("user-token");
        history.push("/");
    };
    return (
        <nav className="bg-white shadow">
            <div className="container px-6 py-4 mx-auto">
                <div className="md:flex md:items-center md:justify-between">
                    <div className="flex-1 md:flex md:items-center md:justify-between">
                        <div className="flex flex-col -mx-4 md:flex-row md:items-center md:mx-8">
                            <Links url="/home" title="Anasayfa" />
                            <Links url="/blogs" title="Yazılar" />
                            <Links url="/categories" title="Kategoriler" />
                            <Links url="/pages" title="Sayfalar" />
                            <Links url="/settings" title="Ayarlar" />
                            <a className="px-2 py-1 mx-2 mt-2 text-sm font-medium text-gray-700 transition-colors duration-200 transform rounded-md md:mt-0 hover:bg-gray-300" href="https://likya-yolu-test.yunus.live/" target="_blank" rel="noreferrer">Test URL</a>
                            <img src="/img/logooo.png" alt="" width="12%" className="mx-auto" />

                        </div>
                        <div className="flex items-center mt-4 md:mt-0">
                            <button onClick={logout} className="hidden mx-4 text-gray-600 md:block hover:text-gray-700 focus:text-gray-700 focus:outline-none">
                                <RiLoginCircleLine className="w-6 h-6" />
                            </button>
                            <button type="button" className="flex items-center focus:outline-none" aria-label="toggle profile dropdown">
                                <div className="w-8 h-8 overflow-hidden border-2 border-gray-400 rounded-full">
                                    <img src={profileImage ? profileImage : null} className="object-cover w-full h-full" alt="avatar" />
                                </div>
                                <h3 className="mx-2 text-sm font-medium text-gray-700 md:hidden">Donatello</h3>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}