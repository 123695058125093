import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'
import { createBrowserHistory } from "history";



import Private from "./Private";

import Login from "./../components/Login";
import Home from "./../components/Home";
import Blogs from "./../components/blog/Blogs"
import AddBlog from "./../components/blog/pages/AddBlog"
import UpdateBlog from "./../components/blog/pages/UpdateBlog"
import BlogContent from "./../components/blog/contents/Blogs"
import Categories from "../components/category/Categories";
import Pages from "../components/pages/Pages";
import Events from "../components/pages/event/Events";
import Stages from "../components/pages/stage/Stages";
import StagesContent from "../components/pages/stage/contents/Stages";
import Info from "../components/pages/info/Info";
import City from "../components/pages/city/City";
import Book from "../components/pages/book/Book";
import Equipment from "../components/pages/equipment/Equipment";
import Gallery from "../components/pages/gallery/Gallery";
import Faq from "../components/pages/faq/Faq";
import Check from "../components/pages/check/Check";
import Settings from '../components/settings/Settings'
import Bulten from '../components/settings/Bulten'
import { Iletisim, IletisimPage } from '../components/settings/Iletisim'
import Yorumlar from '../components/settings/Yorumlar'
import InfoMt from '../components/pages/infoMt/info'

export const history = createBrowserHistory();


const queryClient = new QueryClient()

const GenelRouter = () => {
    return (
        <Router history={history}>
            <QueryClientProvider client={queryClient}>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Private path="/home" component={Home} />
                    <Private path="/blogs" exact component={Blogs} />
                    <Private path="/add-blog" component={AddBlog} />
                    <Private path="/update-blog/:id" component={UpdateBlog} />
                    <Private path="/blog/contents/:id" component={BlogContent} />
                    <Private path="/categories" component={Categories} />
                    <Private path="/pages" exact component={Pages} />
                    <Private path="/pages/events" component={Events} />
                    <Private path="/pages/stages" component={Stages} />
                    <Private path="/pages/infoMt" component={InfoMt} />
                    <Private path="/stage/contents/:id" component={StagesContent} />
                    <Private path="/pages/info" component={Info} />
                    <Private path="/pages/city" component={City} />
                    <Private path="/pages/book" component={Book} />
                    <Private path="/pages/equipment" component={Equipment} />
                    <Private path="/pages/gallery" component={Gallery} />
                    <Private path="/pages/faq" component={Faq} />
                    <Private path="/pages/check" component={Check} />
                    <Private path="/settings" component={Settings} />
                    <Private path="/bulten" component={Bulten} />
                    <Private path="/iletisim" exact component={Iletisim} />
                    <Private path="/iletisim-detay/:id" component={IletisimPage} />
                    <Private path="/yorumlar" component={Yorumlar} />
                </Switch>
            </QueryClientProvider>
        </Router>
    );
};

export default GenelRouter;