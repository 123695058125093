import React from 'react'
import { Link } from "react-router-dom";

export default function Table({ data, bulten }) {
   function Header() {
      return (
         <tr>
            <th className="p-4 font-normal text-gray-900 border-b-2 whitespace-nowrap">
               #
            </th>
            {bulten ? null : <th className="p-4 font-normal text-gray-900 border-b-2 whitespace-nowrap">
               İsim
            </th>
            }

            <th className="p-4 font-normal text-gray-900 border-b-2 whitespace-nowrap">
               Email
            </th>
            {bulten ? null : <th className="p-4 font-normal text-gray-900 border-b-2 whitespace-nowrap">
               Telefon Numarası
            </th>
            }
            {bulten ? null : <th className="p-4 font-normal text-gray-900 border-b-2 whitespace-nowrap">
               Konu
            </th>
            }
            {bulten ? null : <th className="p-4 font-normal text-gray-900 border-b-2 whitespace-nowrap">
               -
            </th>}

         </tr>

      )
   }

   function Data({ datas, sayi }) {
      return (
         <tr key={datas.id} className="text-gray-700">
            <td className="p-4 border-b-2">
               {sayi}
            </td>
            {bulten ? null : <td className="p-4 border-b-2">
               {datas?.fullname}
            </td>}

            <td className="p-4 text-center border-b-2">
               {datas.email}
            </td>

            {bulten ? null : <td className="p-4 border-b-2">
               {datas?.phoneNumber}
            </td>}

            {bulten ? null : <td className="p-4 border-b-2">
               {datas?.subject}
            </td>}
            <td className="p-4 border-b-2">
               {datas?.subject}
            </td>
            {data.message ? <td className="p-4 border-b-2">
               <Link to={`/iletisim-detay/${datas.id}`}>Detay</Link>
            </td> : null}

         </tr>
      )
   }
   console.log("🌵💜🐢data", data)

   return (
      <table className="w-1/2 p-4 mx-auto bg-white rounded-lg shadow">
         <thead>
            <Header />
         </thead>
         <tbody>
            {data && data?.map((datas, s) => {
               return <Data key={datas.id} datas={datas} sayi={s + 1} />
            })}
         </tbody>
      </table>
   )
}
