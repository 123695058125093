import React, { useEffect, useState } from 'react';
import Table from './partials/table';
import Export from './partials/export';
import { useParams } from 'react-router-dom';
import { useQuery } from "react-query";
import Loading from '../Loading';


export function Iletisim() {
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchStage() {
            try {
                setIsLoading(true)
                const result = await fetch('https://likya.api.polyline.works/getContacts')
                const response = await result.json()
                setData(response || response)

                setIsLoading(false)
            } catch (error) {
                console.log("🌵💜🐢 error", error)
            }
        }
        fetchStage()
    }, [])

    return (
        <React.Fragment>
            {isLoading ? <h1>loading</h1> : (
                <div className='w-full my-12 '>
                    <div className="mx-auto">
                        <Export csvData={data} fileName="deneme" />
                        <Table className="mx-auto" data={data} />
                    </div>

                </div>
            )}
        </React.Fragment>
    )
}


export function IletisimPage() {

    let { id } = useParams();


    const { isLoading, data, refetch } = useQuery('repoData', () =>
        fetch(`https://likya.api.polyline.works/getContact/${id}`).then(res =>
            res.json()
        ))
    if (isLoading) {
        return <Loading />
    }
    return (
        <React.Fragment>
            <div className="flex items-center justify-center px-5 py-5">
                <div className="w-full max-w-xl px-5 pt-5 pb-10 mx-auto text-gray-800 bg-white rounded-lg shadow-lg dark:bg-gray-800 ">
                    <div className="w-full">
                        <p className="font-bold text-center text-indigo-500 text-md">
                            {data.fullname ? data.fullname : 'Kişi Kayıtlı Değil'}
                        </p>
                        <p className="text-xs text-center text-gray-500">
                            {data.email ? data.email : 'Mail Adresi Kayıtlı Değil'} - {data.phoneNumber ? data.phoneNumber : 'Telefon Numarası Kayıtlı Değil'}

                        </p>
                    </div>
                    <div className="w-full mb-10">
                        <div className="h-3 text-3xl leading-tight text-left text-indigo-500">
                            “
                        </div>
                        <p className="px-5 text-sm text-center text-gray-600">
                            {data.message ? data.message : 'Mesaj Yok'}
                        </p>
                        <div className="h-3 -mt-3 text-3xl leading-tight text-right text-indigo-500">
                            ”
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}
