import React, { useState, useEffect } from 'react';
import Loading from '../../Loading';
import { AiOutlineCloudUpload } from "react-icons/ai";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useHistory } from 'react-router-dom'
const jwt = require('jsonwebtoken');


export default function AddBlog() {
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [description, setDescription] = useState('');
    const [categoryID, setCategoryID] = useState('');
    const [order, setOrder] = useState(0);
    const [userId, setUserId] = useState('');
    const [previewSource, setPreviewSource] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [category, setCategory] = useState('');
    const [published, setPublished] = useState();
    const [token, setToken] = useState()
    function handleOnChange() {
        setIsChecked(!isChecked);
        setPublished(!isChecked)
    };

    let categorys;

    useEffect(() => {
        async function fetchCategory() {
            const result = await fetch('https://likya.api.polyline.works/getCategories')
            const response = await result.json()
            setCategory(response)
        }
        fetchCategory()
        const token = JSON.parse(localStorage.getItem("user-token")).token
        let decode = jwt.decode(token)
        setToken(token)
        setUserId(decode.id)
    }, [])

    categorys = categoryID

    const handelFileInput = (e) => {
        const file = e.target.files[0];
        previewFile(file);
    }
    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setPreviewSource(reader.result);
        }
    }
    const handleSubmit = (e) => {
        console.log("submit");
        e.preventDefault();
        if (!previewSource) return;
        uploadImage(previewSource, userId)
    }

    const history = useHistory();
    console.log("🌵💜🐢", token)

    const uploadImage = async (banner, userId) => {
        setIsLoading(true);

        let item = { banner, title, subTitle, description, categoryID, order, userId, published };
        await fetch("https://likya.api.polyline.works/createBlog", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-access-token': token,
            },
            body: JSON.stringify(item)
        })

        history.push('/blogs')
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            {
                isLoading ? (
                    <Loading />
                ) : (
                    <div className="flex items-center justify-center w-full">
                        <div className="flex justify-center w-full ">
                            {/* Fuck the world, think about your country  */}
                            <div className="">
                                <div className="text-center">
                                    <h2 className="mt-5 text-3xl font-bold text-gray-900">
                                        Blog Ekleme
                                    </h2>
                                    <p className="flex justify-center mt-2 text-sm text-gray-400 ">
                                        {previewSource && (<img src={previewSource} alt="chosen" style={{ height: '100px' }} />)}
                                    </p>
                                </div>
                                <form className="mt-8 space-y-3" onSubmit={handleSubmit} >
                                    <div className="grid grid-cols-1 space-y-2">
                                        <label className="text-sm font-bold tracking-wide text-gray-500">Resim</label>
                                        <div className="flex items-center justify-center w-full">
                                            <label className="flex flex-col w-full p-10 text-center border-4 border-dashed rounded-lg h-60 group">
                                                <div className="flex flex-col items-center justify-center w-full h-full text-center ">
                                                    <div className="flex justify-center flex-auto w-2/5 mx-auto -mt-10 max-h-48">
                                                        <AiOutlineCloudUpload className="object-center w-1/2 h-36" />
                                                    </div>
                                                    <p className="font-bold text-gray-500 pointer-none "> 348 X 256</p>
                                                    <p className="text-gray-500 pointer-none "> Tıklayınız...</p>
                                                </div>
                                                <input type="file" className="hidden"
                                                    name="banner"
                                                    onChange={handelFileInput}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-sm text-gray-300">
                                            <span>File type: png,jpg,jpeg</span>
                                        </p>
                                    </div>
                                    <div className="grid grid-cols-1 space-y-2">
                                        <label className="text-sm font-bold tracking-wide text-gray-500">Başlık</label>
                                        <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="Blog Başlığı"
                                            name="title" onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 space-y-2">
                                        <label className="text-sm font-bold tracking-wide text-gray-500">Alt Başlık</label>
                                        <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="Alt Blog Başlığı"
                                            name="subTitle" onChange={(e) => setSubTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 space-y-2">
                                        <label className="text-sm font-bold tracking-wide text-gray-500">Detay</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data=""
                                            name="description"
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDescription(data);
                                            }}
                                        />
                                    </div>
                                    <div className="grid items-center grid-cols-3">
                                        <label className="text-gray-700" >
                                            Kategori
                                            <select onChange={(e) => setCategoryID(e.target.value)} value={categorys} className="block px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm w-52 focus:outline-none focus:ring-primary-500 focus:border-primary-500">
                                                <option value="">
                                                    Kategori Seçiniz
                                                </option>
                                                {category ? category?.map((categorya) => {
                                                    return (
                                                        <option value={categorya.id} key={categorya.id}>
                                                            {categorya.name}
                                                        </option>
                                                    )
                                                }) : null
                                                }
                                            </select>
                                        </label>

                                        <label className="text-sm font-bold tracking-wide text-gray-500">Sıra
                                            <br />
                                            <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="number" placeholder="Sıra"
                                                name="Sıra" value={order} onChange={(e) => setOrder(Number(e.target.value))}
                                            />
                                        </label>
                                        <label onChange={handleOnChange} className="flex items-center mb-3 space-x-3">
                                            <input type="checkbox" checked={isChecked} name="published" className="w-6 h-6 bg-white border border-gray-300 rounded-md bg-check checked:bg-blue-500 checked:border-transparent focus:outline-none" />
                                            <span className="font-normal text-gray-700">
                                                Yayınla
                                            </span>
                                        </label>
                                    </div>
                                    <button type="submit" className="flex justify-center w-full p-4 my-5 font-semibold tracking-wide text-gray-100 transition duration-300 ease-in bg-blue-500 rounded-full shadow-lg cursor-pointer focus:outline-none focus:shadow-outline hover:bg-blue-600">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    );
}