import React from 'react';
import { IoMdArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";

import Li from './li';

export default function Page() {

  return (

    <div className="container flex flex-col items-center justify-center w-1/2 mx-auto my-24">
      <div className="w-full px-4 py-5 mb-2 bg-white border rounded-md shadow sm:px-6 dark:bg-gray-800">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
          Likya Yolu Rehberi Sayfaları
        </h3>

      </div>
      <ul className="flex flex-col w-full">
        {/* page -- */}
        <Li title="Etkinlikler" description="Likya Yolu Etkinlikleri" link="/pages/events" />
        <Li title="Günceleri ve Etapları" description="Likya Yolu  Güncesi ve Etapları" link="/pages/stages" />
        <Li title="Genel Bilgi" description="Likya Yolu  Genel Bilgi" link="/pages/info" />
        <Li title="Antik Kentler" description="Likya Yolu Antik Kentler" link="/pages/city" />
        <Li title="Rehber Kitabı" description="Likya Yolu Rehber Kitabı" link="/pages/book" />
        <Li title="Ekipmanlar" description="Ekipman Seçimi ve Tavsiyeler" link="/pages/equipment" />
        <Li title="Galeri" description="Galeri" link="/pages/gallery" />
        <Li title="S.S.S" description="Sıkça Sorulan Sorular" link="/pages/faq" />
        <Li title="Youtube Videolar" description="Videolar" link="/pages/check" />
        <Li title="Hakkında" description="Metin Tüzün Hakkında" link="/pages/infoMt" />
      </ul>
    </div>


    // LY29FJR
  )
}