import React from "react";
import Head from "./partials/Head";
import ImageTd from "./partials/ImageTd";
import Td from "./partials/Td";
import { IoRefreshCircleOutline } from "react-icons/io5";
import { useQuery } from "react-query";
import * as dayjs from 'dayjs'
import 'dayjs/locale/tr'
import { useParams } from 'react-router-dom';
import { BsFileText, BsFileRichtext, BsFillImageFill } from "react-icons/bs";

import AddModal from "./text/AddModal";
import UpdateModal from "./text/UpdateModal";
import DeleteModal from "./partials/DeleteModal";

import AddImageModal from "./image/AddImageModal";
import UpdateImageModal from "./image/UpdateImageModal";

import AddImgTextModal from "./image-text/AddImgTextModal";
import UpdateImgTextModal from "./image-text/UpdateImgTextModal";
import Loading from '../../../Loading';
import CdnImage from "./../../../cdn/Image";

dayjs.locale('tr')




export default function StagesContent() {

    let { id } = useParams();

    const { isLoading, data, refetch } = useQuery('repoData', () =>
        fetch(`https://likya.api.polyline.works/getStage/${id}`).then(res =>
            res.json()
        ))
    if (isLoading) {
        return <Loading />
    }
    return (
        <React.Fragment>
            <div className="container flex items-center justify-center ">
                <div className="w-full">
                    <div className="py-8">
                        <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8 ">
                            <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
                                <table className="min-w-full leading-normal">
                                    <thead>
                                        <tr>
                                            <Head>Sıra</Head>
                                            <Head>Görsel</Head>
                                            <Head>Başlık</Head>
                                            <Head>Tip</Head>

                                            <Head>
                                                <td className="flex items-end justify-end py-1 ml-0">
                                                    <AddImgTextModal data={id} title={<BsFileRichtext className="w-6 h-6" />} class="py-1 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-12 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  h-8 rounded-lg " />
                                                    <AddImageModal data={id} title={<BsFillImageFill className="w-6 h-6" />} class="py-1 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-12 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  h-8 rounded-lg " />
                                                    <AddModal data={id} title={<BsFileText className="w-6 h-6" />} class="py-1 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-12 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  h-8 rounded-lg " />
                                                    <button
                                                        onClick={() => refetch()}
                                                        className="flex items-center justify-center w-full h-8 text-base font-semibold text-center text-white transition duration-200 ease-in bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                                    >
                                                        <IoRefreshCircleOutline className="w-6 h-6" />
                                                    </button>
                                                </td>
                                            </Head>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.Content?.map((content, s) => {
                                            return (
                                                <tr className="bg-blue-300" key={content.id}>
                                                    <Td>{s + 1}</Td>
                                                    <ImageTd>
                                                        <CdnImage id={content?.images} className="object-cover w-10 h-10 mx-auto rounded-full" />
                                                    </ImageTd>
                                                    <Td>{content.title}</Td>
                                                    <Td>{content.contentsType}</Td>
                                                    <Td>
                                                        {content.contentsType === "TEXT" ? <UpdateModal title="Düzenle" class="text-indigo-600 hover:text-indigo-900" id={content.id} /> : ""}
                                                        {content.contentsType === "IMAGETEXT" ? <UpdateImgTextModal title="Düzenle" class="text-indigo-600 hover:text-indigo-900" id={content.id} /> : ""}
                                                        {content.contentsType === "IMAGE" ? <UpdateImageModal title="Düzenle" class="text-indigo-600 hover:text-indigo-900" id={content.id} /> : ""}
                                                    </Td>
                                                    <Td>
                                                        <DeleteModal title="sil" name={content.title} id={content.id} class="text-indigo-600 hover:text-indigo-900" />
                                                    </Td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>

    );
}