import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import Loading from './Loading';
require('dotenv').config()
export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    useEffect(() => {
        if (localStorage.getItem("user-token")) {
            history.push('/home')
        }
    }, [history]);
    async function login() {
        let item = { email, password };
        setIsLoading(true);
        let result = await fetch('https://likya.api.polyline.works/login', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(item)
        });
        result = await result.json();

        if (result.message === "EMAIL NOT FOUND") {
            localStorage.clear()
            window.location.reload()
        } else if (result.message === 'INVALID PASSWORD') {
            localStorage.clear()
            window.location.reload()
        } else {
            setIsLoading(false);
            localStorage.setItem("user-token", JSON.stringify(result));
            history.push('/home');
        }

    }
    return (
        <React.Fragment>
            {isLoading ? (
                <div>
                    <Loading />
                </div>
            ) : (
                <div className="flex items-center justify-center w-full h-screen">
                    <div className="w-full max-w-sm">
                        <div className="my-2">
                            <div className="relative flex ">
                               
                                <input type="text" className="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-r-lg shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="email" placeholder="Your email" value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="my-4">
                            <div className="relative flex ">
                               
                                <input type="password" className="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-r-lg shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="password" placeholder="Your password" value={password}
                                    onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            <button type="submit" onClick={login} className="w-full px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-indigo-600 rounded-lg shadow-md hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}