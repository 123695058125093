import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { AiOutlineCloudUpload, AiOutlineCloseCircle } from "react-icons/ai";
import Loading from '../../Loading';


Modal.setAppElement("#root");


export default function UpdateModal(props) {
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [published, setPublished] = useState();
    const [token, setToken] = useState()

    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function handleOnChange() {
        setIsChecked(!isChecked);
        setPublished(!isChecked)
    };
    const handleSubmit = (e) => {
        console.log("submit");
        e.preventDefault();
        update()
        toggleModal();
    };
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token")).token
        setToken(token)
    }, [])
    async function update() {
        setIsLoading(true);
        let item = { name, comment, published };
        let result = await fetch(`https://likya.api.polyline.works/updateComment/${props.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'x-access-token': token,

            },
            body: JSON.stringify(item)
        });
        result = await result.json();
        JSON.stringify(result)
        setIsLoading(false);
    }


    useEffect(() => {
        fetch(`https://likya.api.polyline.works/getComment/${props.id}`)
            .then(response => response.json())
            .then(data => {
                setName(data?.name)
                setComment(data?.comment)
                setIsChecked(data?.published)
            })
    }, [props.id]);
    return (
        <React.Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <div>
                    <button className={props.class} onClick={toggleModal}>{props.title}</button>
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={toggleModal}
                        style={{
                            content: {
                                background: 'transparent',
                                outline: 'none',
                                border: 'transparent',
                                top: 2,
                                overflow: 'hidden'
                            },
                        }}
                    >
                        <button onClick={toggleModal} className="float-right text-red-900" >
                            <AiOutlineCloseCircle className="w-6 h-6 text-red-900" />
                        </button>
                        <div className="relative flex justify-center px-4 sm:px-6 lg:px-8">
                            <div className="absolute inset-0 z-0 opacity-60">
                            </div>
                            <div className="z-10 w-full p-10 bg-white sm:max-w-lg rounded-xl">
                                <div className="text-center">
                                    <h2 className="mt-5 text-3xl font-bold text-gray-900">
                                        Yorum Düzenleme
                                    </h2>
                                </div>
                                <form className="mb-2 space-y-3 " onSubmit={handleSubmit} >
                                    <div className="grid grid-cols-1 space-y-2">
                                        <label className="text-sm font-bold tracking-wide text-gray-500">Ad</label>
                                        <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="ad"
                                            name="name" defaultValue={name} onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 space-y-2">
                                        <label className="text-sm font-bold tracking-wide text-gray-500">Yorum</label>

                                        <textarea className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" placeholder="yorum" cols="30" rows="10" defaultValue={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                    </div>
                                    <label onChange={handleOnChange} className="flex items-center mb-3 space-x-3">
                                        <input type="checkbox" checked={isChecked} name="published" className="w-6 h-6 bg-white border border-gray-300 rounded-md resize-none bg-check checked:bg-blue-500 checked:border-transparent focus:outline-none" />
                                        <span className="font-normal text-gray-700">
                                            Yayınla
                                        </span>
                                    </label>
                                    <div>
                                        <button type="submit" className="flex justify-center w-full p-4 my-5 font-semibold tracking-wide text-gray-100 transition duration-300 ease-in bg-blue-500 rounded-full shadow-lg cursor-pointer focus:outline-none focus:shadow-outline hover:bg-blue-600">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </React.Fragment>
    );
}
