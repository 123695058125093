import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function Export({ csvData, fileName }) {
   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
   const fileExtension = '.xlsx';

   const exportToCSV = (csvData, fileName) => {
      const dat = [
         {
            "SKU": "CCB-92",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCB-200",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-276",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-280",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-281",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCB-291",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCB-300",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-301",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-303",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCB-304",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCB-305",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-306",
            "sizes": [],
            "TL": 295
         },
         {
            "SKU": "CCB-307",
            "sizes": [],
            "TL": 245
         },
         {
            "SKU": "CCB-349",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCB-350",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCB-351",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCB-352",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CBS-353",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CBS-354",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-355",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-359",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-360",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CES-362",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CBS-375",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-377",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CBS-378",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CBS-379",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CBS-380",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCB-381",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-382",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-385",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCB-386",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-387",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCB-389",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CES-390",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CES-391",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CES-392",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCN-396",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "L",
                  "price": 995
               },
               {
                  "size": "SP",
                  "price": 845
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-398",
            "sizes": [
               {
                  "size": "M",
                  "price": 795
               },
               {
                  "size": "L",
                  "price": 975
               },
               {
                  "size": "MP",
                  "price": 895
               },
               {
                  "size": "XXL",
                  "price": 1175
               }
            ],
            "TL": 685
         },
         {
            "SKU": "CCN-400",
            "sizes": [
               {
                  "size": "M",
                  "price": 975
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 1045
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-402",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "L",
                  "price": 995
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-405",
            "sizes": [
               {
                  "size": "SP",
                  "price": 745
               }
            ],
            "TL": 685
         },
         {
            "SKU": "CCN-409",
            "sizes": [
               {
                  "size": "M",
                  "price": 685
               },
               {
                  "size": "L",
                  "price": 745
               },
               {
                  "size": "SP",
                  "price": 595
               },
               {
                  "size": "XXL",
                  "price": 975
               }
            ],
            "TL": 545
         },
         {
            "SKU": "CNS-410",
            "sizes": [
               {
                  "size": "M",
                  "price": 795
               },
               {
                  "size": "L",
                  "price": 845
               },
               {
                  "size": "SP",
                  "price": 745
               },
               {
                  "size": "C",
                  "price": 895
               }
            ],
            "TL": 685
         },
         {
            "SKU": "CCB-421",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCN-422",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CCN-448",
            "sizes": [
               {
                  "size": "M",
                  "price": 745
               },
               {
                  "size": "L",
                  "price": 895
               },
               {
                  "size": "XL",
                  "price": 925
               },
               {
                  "size": "MP",
                  "price": 795
               }
            ],
            "TL": 685
         },
         {
            "SKU": "CCR-449",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CNS-451",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCB-460",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCN-461",
            "sizes": [
               {
                  "size": "L",
                  "price": 595
               },
               {
                  "size": "XL",
                  "price": 685
               }
            ],
            "TL": 545
         },
         {
            "SKU": "CCP-462",
            "sizes": [],
            "TL": 275
         },
         {
            "SKU": "CCN-467",
            "sizes": [
               {
                  "size": "M",
                  "price": 975
               },
               {
                  "size": "L",
                  "price": 1175
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 1045
               },
               {
                  "size": "XL",
                  "price": 1295
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-470",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "L",
                  "price": 1045
               },
               {
                  "size": "SP",
                  "price": 845
               },
               {
                  "size": "MP",
                  "price": 995
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CRS-478",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRS-481",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-484",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCR-485",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-486",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCP-488",
            "sizes": [],
            "TL": 275
         },
         {
            "SKU": "CEM-492",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCN-494",
            "sizes": [
               {
                  "size": "SP",
                  "price": 895
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-496",
            "sizes": [
               {
                  "size": "M",
                  "price": 895
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCB-497",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CBS-498",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-499",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCN-503",
            "sizes": [],
            "TL": 975
         },
         {
            "SKU": "CCB-504",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCN-507",
            "sizes": [
               {
                  "size": "M",
                  "price": 795
               },
               {
                  "size": "L",
                  "price": 925
               },
               {
                  "size": "XL",
                  "price": 1175
               },
               {
                  "size": "MP",
                  "price": 845
               },
               {
                  "size": "XXL",
                  "price": 1295
               },
               {
                  "size": "XXXL",
                  "price": 1365
               }
            ],
            "TL": 745
         },
         {
            "SKU": "CBS-508",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCB-509",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCB-511",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBS-512",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-514",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-516",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCN-520",
            "sizes": [
               {
                  "size": "M",
                  "price": 975
               },
               {
                  "size": "L",
                  "price": 1175
               },
               {
                  "size": "XL",
                  "price": 1295
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 1025
               },
               {
                  "size": "XXL",
                  "price": 1365
               },
               {
                  "size": "XXXL",
                  "price": 1585
               }
            ],
            "TL": 845
         },
         {
            "SKU": "CCN-521",
            "sizes": [
               {
                  "size": "M",
                  "price": 975
               },
               {
                  "size": "L",
                  "price": 1175
               },
               {
                  "size": "XL",
                  "price": 1295
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 1025
               },
               {
                  "size": "XXL",
                  "price": 1365
               },
               {
                  "size": "XXXL",
                  "price": 1585
               }
            ],
            "TL": 845
         },
         {
            "SKU": "CCN-522",
            "sizes": [
               {
                  "size": "M",
                  "price": 975
               },
               {
                  "size": "L",
                  "price": 1175
               },
               {
                  "size": "XL",
                  "price": 1295
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 1025
               },
               {
                  "size": "XXL",
                  "price": 1365
               }
            ],
            "TL": 845
         },
         {
            "SKU": "CBS-524",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCB-525",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCN-532",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 975
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CRM-546",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-547",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-548",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CEM-550",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CES-551",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CEM-553",
            "sizes": [],
            "TL": 295
         },
         {
            "SKU": "CCN-555",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 995
               },
               {
                  "size": "L",
                  "price": 1025
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CRM-561",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-562",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-563",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-564",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CEM-567",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCB-574",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCB-575",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-582",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CBM-585",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-586",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-587",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-588",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-589",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-590",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-591",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-592",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-593",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CCB-594",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-598",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CEM-603",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CRM-607",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-608",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CNS-615",
            "sizes": [
               {
                  "size": "SP",
                  "price": 845
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CNS-620",
            "sizes": [],
            "TL": 845
         },
         {
            "SKU": "CBS-622",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CNS-623",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CNS-625",
            "sizes": [
               {
                  "size": "SP",
                  "price": 845
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-626",
            "sizes": [
               {
                  "size": "M",
                  "price": 975
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 1045
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-627",
            "sizes": [
               {
                  "size": "SP",
                  "price": 1225
               }
            ],
            "TL": 1175
         },
         {
            "SKU": "CCP-628",
            "sizes": [],
            "TL": 275
         },
         {
            "SKU": "CCP-630",
            "sizes": [],
            "TL": 275
         },
         {
            "SKU": "CCP-631",
            "sizes": [],
            "TL": 275
         },
         {
            "SKU": "CCN-633",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CCH-635",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CCN-636",
            "sizes": [
               {
                  "size": "SP",
                  "price": 1045
               }
            ],
            "TL": 995
         },
         {
            "SKU": "CNS-640",
            "sizes": [
               {
                  "size": "M",
                  "price": 975
               },
               {
                  "size": "SP",
                  "price": 925
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-641",
            "sizes": [],
            "TL": 845
         },
         {
            "SKU": "CCH-647",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CCH-648",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CCH-649",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CCB-650",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBM-651",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-654",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCN-655",
            "sizes": [
               {
                  "size": "SP",
                  "price": 1295
               }
            ],
            "TL": 1175
         },
         {
            "SKU": "CCN-656",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 975
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CNS-659",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "L",
                  "price": 995
               },
               {
                  "size": "MP",
                  "price": 975
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-664",
            "sizes": [
               {
                  "size": "M",
                  "price": 1045
               },
               {
                  "size": "SP",
                  "price": 895
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CNS-665",
            "sizes": [],
            "TL": 845
         },
         {
            "SKU": "CCR-669",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CRS-670",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCR-671",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCR-672",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCR-673",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CRS-674",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCR-675",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CBM-680",
            "sizes": [],
            "TL": 975
         },
         {
            "SKU": "CBM-681",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CBM-682",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CBM-683",
            "sizes": [],
            "TL": 975
         },
         {
            "SKU": "CBM-684",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBM-685",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCN-688",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "MP",
                  "price": 995
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-692",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "MP",
                  "price": 995
               },
               {
                  "size": "XXL",
                  "price": 1095
               },
               {
                  "size": "L",
                  "price": 1045
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-694",
            "sizes": [
               {
                  "size": "M",
                  "price": 975
               },
               {
                  "size": "L",
                  "price": 1045
               },
               {
                  "size": "SP",
                  "price": 925
               },
               {
                  "size": "MP",
                  "price": 995
               }
            ],
            "TL": 895
         },
         {
            "SKU": "CCI-696",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCI-697",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CLM-701",
            "sizes": [],
            "TL": 275
         },
         {
            "SKU": "CLM-702",
            "sizes": [],
            "TL": 275
         },
         {
            "SKU": "CCL-703",
            "sizes": [],
            "TL": 275
         },
         {
            "SKU": "CLM-704",
            "sizes": [],
            "TL": 275
         },
         {
            "SKU": "CCN-709",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 975
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCB-720",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-722",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CBM-723",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCB-724",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCN-726",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "MP",
                  "price": 995
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CBM-729",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CBM-730",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CBM-731",
            "sizes": [],
            "TL": 1045
         },
         {
            "SKU": "CBM-732",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CCN-736",
            "sizes": [
               {
                  "size": "M",
                  "price": 895
               },
               {
                  "size": "L",
                  "price": 1175
               },
               {
                  "size": "MP",
                  "price": 995
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CRS-739",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCR-745",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-748",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CES-751",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CES-752",
            "sizes": [],
            "TL": 455
         },
         {
            "SKU": "CCE-753",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCE-755",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCE-756",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCE-757",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCE-758",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CBS-761",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CNS-762",
            "sizes": [],
            "TL": 1365
         },
         {
            "SKU": "CBM-764",
            "sizes": [],
            "TL": 1045
         },
         {
            "SKU": "CEM-771",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CNE-777",
            "sizes": [],
            "TL": 2135
         },
         {
            "SKU": "CBM-778",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-779",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBS-781",
            "sizes": [],
            "TL": 925
         },
         {
            "SKU": "CLM-782",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CBM-784",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBS-785",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBM-788",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CBM-792",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBM-795",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBS-798",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CNS-802",
            "sizes": [
               {
                  "size": "M",
                  "price": 975
               },
               {
                  "size": "SP",
                  "price": 795
               }
            ],
            "TL": 685
         },
         {
            "SKU": "CCH-803",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-810",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CNE-814",
            "sizes": [
               {
                  "size": "SP",
                  "price": 895
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CBM-816",
            "sizes": [],
            "TL": 975
         },
         {
            "SKU": "CCN-817",
            "sizes": [
               {
                  "size": "M",
                  "price": 475
               },
               {
                  "size": "L",
                  "price": 545
               },
               {
                  "size": "XL",
                  "price": 645
               },
               {
                  "size": "MP",
                  "price": 515
               }
            ],
            "TL": 445
         },
         {
            "SKU": "CCN-818",
            "sizes": [
               {
                  "size": "M",
                  "price": 475
               },
               {
                  "size": "L",
                  "price": 545
               },
               {
                  "size": "XL",
                  "price": 645
               },
               {
                  "size": "MP",
                  "price": 515
               }
            ],
            "TL": 445
         },
         {
            "SKU": "CCN-819",
            "sizes": [
               {
                  "size": "M",
                  "price": 475
               },
               {
                  "size": "L",
                  "price": 545
               },
               {
                  "size": "XL",
                  "price": 645
               },
               {
                  "size": "MP",
                  "price": 515
               }
            ],
            "TL": 445
         },
         {
            "SKU": "CEM-820",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CBM-822",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBS-823",
            "sizes": [],
            "TL": 925
         },
         {
            "SKU": "CBM-825",
            "sizes": [],
            "TL": 925
         },
         {
            "SKU": "CCH-827",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CBM-828",
            "sizes": [],
            "TL": 925
         },
         {
            "SKU": "CLM-830",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CLM-831",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCH-833",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCE-834",
            "sizes": [
               {
                  "size": "M",
                  "price": 795
               }
            ],
            "TL": 545
         },
         {
            "SKU": "CBS-835",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCR-836",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CBS-839",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBM-841",
            "sizes": [],
            "TL": 925
         },
         {
            "SKU": "CBM-842",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-845",
            "sizes": [],
            "TL": 925
         },
         {
            "SKU": "CCB-847",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-851",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-852",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCN-854",
            "sizes": [
               {
                  "size": "SP",
                  "price": 1075
               }
            ],
            "TL": 975
         },
         {
            "SKU": "CLM-858",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CES-859",
            "sizes": [
               {
                  "size": "M",
                  "price": 445
               }
            ],
            "TL": 345
         },
         {
            "SKU": "CCB-860",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-861",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-866",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CBM-867",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-868",
            "sizes": [],
            "TL": 925
         },
         {
            "SKU": "CBM-869",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-870",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBM-871",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCP-872",
            "sizes": [],
            "TL": 295
         },
         {
            "SKU": "CCP-873",
            "sizes": [],
            "TL": 295
         },
         {
            "SKU": "CCP-874",
            "sizes": [],
            "TL": 295
         },
         {
            "SKU": "CCP-875",
            "sizes": [],
            "TL": 225
         },
         {
            "SKU": "CCP-876",
            "sizes": [],
            "TL": 275
         },
         {
            "SKU": "CCB-877",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBS-881",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CPM-885",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CPM-886",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CBM-887",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-891",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBM-893",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBM-894",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBM-895",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCN-900",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "L",
                  "price": 995
               },
               {
                  "size": "SP",
                  "price": 895
               },
               {
                  "size": "MP",
                  "price": 975
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CCN-901",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "SP",
                  "price": 845
               },
               {
                  "size": "MP",
                  "price": 975
               }
            ],
            "TL": 745
         },
         {
            "SKU": "CCN-902",
            "sizes": [],
            "TL": 1585
         },
         {
            "SKU": "CCN-903",
            "sizes": [
               {
                  "size": "M",
                  "price": 925
               },
               {
                  "size": "L",
                  "price": 1045
               },
               {
                  "size": "SP",
                  "price": 845
               },
               {
                  "size": "MP",
                  "price": 975
               }
            ],
            "TL": 745
         },
         {
            "SKU": "CCB-904",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-905",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCN-906",
            "sizes": [
               {
                  "size": "SP",
                  "price": 1245
               },
               {
                  "size": "MP",
                  "price": 1295
               }
            ],
            "TL": 1175
         },
         {
            "SKU": "CCN-907",
            "sizes": [
               {
                  "size": "SP",
                  "price": 1045
               }
            ],
            "TL": 995
         },
         {
            "SKU": "CLM-910",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCN-912",
            "sizes": [],
            "TL": 1585
         },
         {
            "SKU": "CCB-913",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-914",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CBM-915",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-917",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-918",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CPM-919",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CNE-920",
            "sizes": [
               {
                  "size": "SP",
                  "price": 995
               }
            ],
            "TL": 975
         },
         {
            "SKU": "CNE-921",
            "sizes": [
               {
                  "size": "SP",
                  "price": 995
               }
            ],
            "TL": 975
         },
         {
            "SKU": "CNE-923",
            "sizes": [
               {
                  "size": "SP",
                  "price": 895
               }
            ],
            "TL": 795
         },
         {
            "SKU": "CBM-926",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCB-927",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CEM-928",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CEM-929",
            "sizes": [
               {
                  "size": "L",
                  "price": 565
               }
            ],
            "TL": 495
         },
         {
            "SKU": "CCN-930",
            "sizes": [],
            "TL": 1225
         },
         {
            "SKU": "CCB-931",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCB-932",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-933",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CES-935",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CEM-936",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCN-937",
            "sizes": [
               {
                  "size": "M",
                  "price": 975
               }
            ],
            "TL": 685
         },
         {
            "SKU": "CCN-938",
            "sizes": [
               {
                  "size": "M",
                  "price": 895
               },
               {
                  "size": "SP",
                  "price": 795
               }
            ],
            "TL": 685
         },
         {
            "SKU": "CEM-939",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CEM-940",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CLM-943",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CNS-944",
            "sizes": [],
            "TL": 845
         },
         {
            "SKU": "CBM-945",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCI-946",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CEM-949",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CPM-950",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCE-951",
            "sizes": [],
            "TL": 245
         },
         {
            "SKU": "CPM-952",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-953",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-954",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-955",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-956",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-957",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRM-958",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCB-959",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-960",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CEM-962",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-965",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CBS-966",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-967",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-968",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCE-971",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CPM-972",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCI-974",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCN-975",
            "sizes": [
               {
                  "size": "M",
                  "price": 1175
               },
               {
                  "size": "SP",
                  "price": 1045
               }
            ],
            "TL": 975
         },
         {
            "SKU": "CCE-976",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CBM-977",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCN-979",
            "sizes": [
               {
                  "size": "M",
                  "price": 85
               },
               {
                  "size": "L",
                  "price": 140
               },
               {
                  "size": "XL",
                  "price": 175
               },
               {
                  "size": "MP",
                  "price": 100
               }
            ],
            "TL": 75
         },
         {
            "SKU": "CES-986",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CES-987",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CES-988",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CES-989",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCE-990",
            "sizes": [],
            "TL": 245
         },
         {
            "SKU": "CCE-991",
            "sizes": [],
            "TL": 245
         },
         {
            "SKU": "CCE-992",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCE-993",
            "sizes": [],
            "TL": 245
         },
         {
            "SKU": "CCE-994",
            "sizes": [],
            "TL": 295
         },
         {
            "SKU": "CCE-995",
            "sizes": [],
            "TL": 295
         },
         {
            "SKU": "CCE-996",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCE-997",
            "sizes": [],
            "TL": 245
         },
         {
            "SKU": "CCE-998",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCE-999",
            "sizes": [],
            "TL": 245
         },
         {
            "SKU": "CCB-1000",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCE-1001",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCE-1002",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CCI-1007",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1009",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1010",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1011",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1012",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CEM-1020",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1021",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1022",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1023",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1024",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1025",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1026",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1027",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1028",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1029",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1030",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1031",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1032",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1033",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1034",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1035",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1036",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1037",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1038",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1039",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CEM-1040",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CES-1047",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCI-1058",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1059",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1060",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1061",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1063",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1064",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1065",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCI-1066",
            "sizes": [],
            "TL": 195
         },
         {
            "SKU": "CCB-1082",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-1084",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CBM-1091",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBM-1092",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-1093",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CBM-1094",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCB-1097",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-1098",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CBS-1099",
            "sizes": [],
            "TL": 925
         },
         {
            "SKU": "CCB-1100",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CCH-1102",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CRM-1103",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CEM-1104",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCR-1105",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CNS-1106",
            "sizes": [
               {
                  "size": "M",
                  "price": 1045
               },
               {
                  "size": "L",
                  "price": 1175
               }
            ],
            "TL": 925
         },
         {
            "SKU": "CCB-1109",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CBS-1110",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-1111",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-1112",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-1113",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CBS-1116",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCN-1117",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCN-1118",
            "sizes": [
               {
                  "size": "M",
                  "price": 470
               },
               {
                  "size": "L",
                  "price": 520
               },
               {
                  "size": "XL",
                  "price": 540
               }
            ],
            "TL": 445
         },
         {
            "SKU": "CCN-1119",
            "sizes": [
               {
                  "size": "M",
                  "price": 470
               },
               {
                  "size": "L",
                  "price": 520
               },
               {
                  "size": "XL",
                  "price": 540
               },
               {
                  "size": "MP",
                  "price": 495
               }
            ],
            "TL": 445
         },
         {
            "SKU": "CCN-1121",
            "sizes": [
               {
                  "size": "M",
                  "price": 470
               },
               {
                  "size": "L",
                  "price": 520
               },
               {
                  "size": "XL",
                  "price": 540
               },
               {
                  "size": "MP",
                  "price": 495
               }
            ],
            "TL": 445
         },
         {
            "SKU": "CCN-1122",
            "sizes": [
               {
                  "size": "M",
                  "price": 470
               },
               {
                  "size": "L",
                  "price": 520
               },
               {
                  "size": "XL",
                  "price": 540
               },
               {
                  "size": "MP",
                  "price": 495
               }
            ],
            "TL": 445
         },
         {
            "SKU": "CCN-1123",
            "sizes": [
               {
                  "size": "M",
                  "price": 470
               },
               {
                  "size": "L",
                  "price": 520
               },
               {
                  "size": "XL",
                  "price": 540
               },
               {
                  "size": "MP",
                  "price": 495
               }
            ],
            "TL": 445
         },
         {
            "SKU": "CCN-1125",
            "sizes": [
               {
                  "size": "M",
                  "price": 470
               },
               {
                  "size": "L",
                  "price": 520
               },
               {
                  "size": "XL",
                  "price": 540
               },
               {
                  "size": "MP",
                  "price": 495
               }
            ],
            "TL": 445
         },
         {
            "SKU": "CCN-1126",
            "sizes": [
               {
                  "size": "M",
                  "price": 470
               },
               {
                  "size": "L",
                  "price": 520
               },
               {
                  "size": "XL",
                  "price": 540
               },
               {
                  "size": "MP",
                  "price": 495
               }
            ],
            "TL": 445
         },
         {
            "SKU": "CCB-1129",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-1130",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CBM-1131",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCB-1133",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-1134",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CBM-1135",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CCB-1136",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CBM-1137",
            "sizes": [],
            "TL": 795
         },
         {
            "SKU": "CNE-1139",
            "sizes": [],
            "TL": 1045
         },
         {
            "SKU": "CCB-1140",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCN-1141",
            "sizes": [
               {
                  "size": "L",
                  "price": 1175
               },
               {
                  "size": "XXL",
                  "price": 1225
               }
            ],
            "TL": 995
         },
         {
            "SKU": "CBM-1144",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CNE-1147",
            "sizes": [],
            "TL": 2135
         },
         {
            "SKU": "CCN-1148",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CCN-1149",
            "sizes": [],
            "TL": 1225
         },
         {
            "SKU": "CBS-1152",
            "sizes": [],
            "TL": 745
         },
         {
            "SKU": "CBS-1153",
            "sizes": [],
            "TL": 745
         },
         {
            "SKU": "CNS-1154",
            "sizes": [],
            "TL": 1585
         },
         {
            "SKU": "CEM-1155",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CEM-1156",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CEM-1157",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CEM-1158",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CEM-1159",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CEM-1160",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CEM-1161",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CEM-1162",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CCR-1163",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCR-1164",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCR-1165",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CCR-1166",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CRS-1167",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CNS-1170",
            "sizes": [],
            "TL": 1585
         },
         {
            "SKU": "CCB-1171",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CCN-1175",
            "sizes": [
               {
                  "size": "M",
                  "price": 995
               },
               {
                  "size": "L",
                  "price": 1095
               },
               {
                  "size": "SP",
                  "price": 945
               },
               {
                  "size": "MP",
                  "price": 1045
               }
            ],
            "TL": 895
         },
         {
            "SKU": "CEM-1177",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CEM-1178",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CEM-1179",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CPM-1180",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CPM-1181",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CEM-1182",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-1184",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CCB-1185",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CCB-1187",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CCB-1188",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CBS-1189",
            "sizes": [],
            "TL": 925
         },
         {
            "SKU": "CCB-1190",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-1191",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-1192",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CBM-1193",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-1194",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-1195",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-1196",
            "sizes": [],
            "TL": 745
         },
         {
            "SKU": "CBS-1197",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-1198",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-1199",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-1201",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBM-1202",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-1203",
            "sizes": [],
            "TL": 895
         },
         {
            "SKU": "CBM-1204",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CBS-1205",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CCB-1206",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CES-1207",
            "sizes": [],
            "TL": 525
         },
         {
            "SKU": "CCE-1208",
            "sizes": [],
            "TL": 525
         },
         {
            "SKU": "CNS-1209",
            "sizes": [],
            "TL": 1585
         },
         {
            "SKU": "CBS-1210",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CPM-1215",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CPM-1216",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CPM-1217",
            "sizes": [],
            "TL": 345
         },
         {
            "SKU": "CBS-1218",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-1219",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-1220",
            "sizes": [],
            "TL": 995
         },
         {
            "SKU": "CCB-1221",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-1222",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-1223",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-1224",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CBS-1225",
            "sizes": [],
            "TL": 925
         },
         {
            "SKU": "CBS-1226",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-1227",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CBM-1228",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-1229",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CBS-1230",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-1231",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-1232",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-1233",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-1234",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CBS-1235",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CBS-1236",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCB-1237",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCB-1238",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CCB-1239",
            "sizes": [],
            "TL": 595
         },
         {
            "SKU": "CCB-1240",
            "sizes": [],
            "TL": 445
         },
         {
            "SKU": "CBS-1241",
            "sizes": [],
            "TL": 685
         },
         {
            "SKU": "CCH-1242",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCH-1244",
            "sizes": [],
            "TL": 545
         },
         {
            "SKU": "CCH-1245",
            "sizes": [],
            "TL": 475
         },
         {
            "SKU": "CNS-1246",
            "sizes": [
               {
                  "size": "M",
                  "price": 1045
               },
               {
                  "size": "L",
                  "price": 1175
               },
               {
                  "size": "SP",
                  "price": 995
               }
            ],
            "TL": 925
         },
         {
            "SKU": "CES-1247",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CES-1248",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CES-1249",
            "sizes": [],
            "TL": 495
         },
         {
            "SKU": "CRS-1251",
            "sizes": [],
            "TL": 495
         }
      ]
      const ws = XLSX.utils.json_to_sheet(dat);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
   }
   return (
      <button className='w-24 px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-indigo-700 rounded-lg shadow-md hover:bg-indigo-800 focus:-indigo-600 focus:outline-none opacity-70 ' onClick={(e) => exportToCSV(csvData, fileName)}>Export</button>
   )
}
