import React, { useEffect, useState } from "react";
import Head from "./partials/Head";
import ImageTd from "./partials/ImageTd";
import Td from "./partials/Td";
import StatusTd from "./partials/StatusTd";
import { IoAddCircleOutline, IoRefreshCircleOutline } from "react-icons/io5";
import * as dayjs from 'dayjs'
import 'dayjs/locale/tr'


import DeleteModal from "./partials/DeleteModal";
import Loading from '../Loading';
import CdnImage from "./../cdn/Image";
import { Link } from "react-router-dom";

dayjs.locale('tr')




export default function Blogs() {
    const [isLoading, setIsLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const [data, setData] = useState('')
    useEffect(() => {
        async function fetchBlog() {
            setIsLoading(true)
            const result = await fetch('https://likya.api.polyline.works/getListBlogs')
            const response = await result.json()
            setData(response)
            setIsLoading(false)
        }
        fetchBlog()
    }, [refreshKey])

    const veri = () => {
        console.log("🌵💜🐢data:::", data)
        if (data.length === 0) {
            return (
                <>
                    <div>Data Yok</div>
                </>
            );
        }

        return data?.map((blog, s) => {
            return (
                <tr key={blog.id}>
                    <Td>{s + 1}</Td>
                    <ImageTd >
                        <CdnImage alt={blog.title} id={blog.banner} className="object-cover w-10 h-10 mx-auto rounded-full" />
                    </ImageTd>
                    <Td>{blog.title}</Td>
                    <Td>{blog.categories.name}</Td>
                    <Td>{dayjs(blog.createDate).format("DD/MM/YYYY")}</Td>
                    <StatusTd status={blog.published} />
                    <Td>
                        <Link className="text-indigo-600 hover:text-indigo-900" to={`/update-blog/${blog.id}`}>Düzenle</Link>
                    </Td>
                    <Td><Link className="text-indigo-600 hover:text-indigo-900" to={`/blog/contents/${blog.id}`}>İçerikler</Link></Td>
                    <Td>
                        <DeleteModal title="Sil" name={blog.title} class="text-indigo-600 hover:text-indigo-900" id={blog.id} />
                    </Td>
                </tr>
            );
        });
    };
    return (
        <React.Fragment>
            {isLoading ? (<Loading />) : (
                <div className="container flex items-center justify-center ">
                    <div className="w-full">
                        <div className="py-8">
                            <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8 ">
                                <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr>
                                                <Head>#</Head>
                                                <Head>Görsel</Head>
                                                <Head>Başlık</Head>
                                                <Head>Kategori</Head>
                                                <Head>Oluşturma Tarihi</Head>
                                                <Head>Durum</Head>
                                                <Head></Head>
                                                <Head></Head>
                                                <Head>
                                                    <Link to="/add-blog">
                                                        <IoAddCircleOutline className="w-6 h-6" />
                                                    </Link>
                                                </Head>
                                                <Head>
                                                    <button
                                                        onClick={() => setRefreshKey(oldKey => oldKey + 1)}
                                                        className="flex items-center justify-center "
                                                    >
                                                        <IoRefreshCircleOutline className="w-6 h-6" />
                                                    </button>
                                                </Head>
                                            </tr>
                                        </thead>
                                        <tbody>{veri()}</tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            )}

        </React.Fragment>

    );
}