import React, { useEffect, useState } from "react";
import Head from "./partials/Head";
import ImageTd from "./partials/ImageTd";
import Td from "./partials/Td";
import { IoAddCircleOutline, IoRefreshCircleOutline } from "react-icons/io5";
import * as dayjs from 'dayjs'
import 'dayjs/locale/tr'
import AddModal from "./partials/AddModal";
import UpdateModal from "./partials/UpdateModal";
import DeleteModal from "./partials/DeleteModal";
import Loading from '../../Loading';
import CdnImage from '../../cdn/Image'
dayjs.locale('tr')




export default function Events() {
    const [data, setData] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        async function fetchEvent() {
            setIsLoading(true)
            const result = await fetch('https://likya.api.polyline.works/getEvents')
            const response = await result.json()
            setData(response)
            setIsLoading(false)
        }
        fetchEvent()
    }, [refreshKey])


    const veri = (data) => {
        if (data.length === 0) {
            return <div>Data Yok</div>;
        }
        return data.map((event, s) => {
            return (
                <tr key={event.id}>
                    <Td>{s + 1}</Td>
                    <ImageTd >
                        <CdnImage alt={event.title} id={event.image} className="mx-auto object-cover rounded-full h-10 w-10 " />
                    </ImageTd>
                    <Td>{event.title}</Td>
                    <Td>{dayjs(event.createDate).format("DD/MM/YYYY")}</Td>
                    <Td>
                        <UpdateModal title="Düzenle" class="text-indigo-600 hover:text-indigo-900" id={event.id} />
                    </Td>
                    <Td>
                        <DeleteModal title="sil" name={event.title} class="text-indigo-600 hover:text-indigo-900" id={event.id} />
                    </Td>
                </tr>
            );
        });
    };
    return (
        <React.Fragment>
            {isLoading ? (<Loading />) : (
                <div className="flex justify-center items-center container ">
                    <div className="w-full">
                        <div className="py-8">
                            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto ">
                                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr>
                                                <Head>#</Head>
                                                <Head>Görsel</Head>
                                                <Head>Başlık</Head>
                                                <Head>Oluşturma Tarihi</Head>
                                                <Head></Head>
                                                <Head></Head>
                                                <Head>
                                                    <AddModal title={<IoAddCircleOutline className="w-6 h-6" />} class="py-1 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 h-8 rounded-lg " />
                                                </Head>
                                                <Head>
                                                    <button
                                                        onClick={() => setRefreshKey(oldKey => oldKey + 1)}
                                                        className="flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  h-8 rounded-lg "
                                                    >
                                                        <IoRefreshCircleOutline className="w-6 h-6" />
                                                    </button>
                                                </Head>
                                            </tr>
                                        </thead>
                                        <tbody>{veri(data)}</tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </React.Fragment>

    );
}