import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FiAlertTriangle } from "react-icons/fi";
import Loading from '../../../Loading';

Modal.setAppElement("#root");


export default function DeleteModal(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState()

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token")).token
        setToken(token)
    }, [])
    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function deleteCategory() {
        setIsLoading(true);
        fetch(`https://likya.api.polyline.works/deleteEquipment/${props.id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'x-access-token': token,
            },
        })
            .then(res =>
                res.json()
            )
        toggleModal()
        setIsLoading(false);
    }
    return (
        <React.Fragment>
            {
                isLoading ? (
                    <Loading />
                ) : (
                    <div>
                        <button className={props.class} onClick={toggleModal}>{props.title}</button>
                        <Modal
                            isOpen={isOpen}
                            onRequestClose={toggleModal}
                            style={{
                                overlay: {
                                    backgroundColor: 'transparent'
                                },
                                content: {
                                    background: 'transparent',
                                    outline: 'none',
                                    border: 'transparent',
                                }
                            }}
                        >
                            <div className="fixed z-10 inset-0 overflow-y-auto" >
                                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" >&#8203;</span>
                                    <div
                                        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div
                                                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <FiAlertTriangle className="h-6 w-6 text-red-600" />
                                                </div>
                                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                                        {props.name} Silinsin mi?
                                                    </h3>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            {props.name} silinecektir. Bu işlem geri alınamaz.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                            <button onClick={() => { deleteCategory() }}
                                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                                Sil
                                            </button>
                                            <button className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={toggleModal}>
                                                Kapat
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                )
            }
        </React.Fragment>
    );
}
