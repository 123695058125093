import React from "react";
import { IoMdArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";


export default function Li(props) {

    return (

        <li className="border-gray-400 flex flex-row mb-2">
            <div className="transition duration-500 shadow ease-in-out transform hover:-translate-y-1 hover:shadow-lg select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                <div className="flex-1 pl-1 md:mr-16">
                    <div className="font-medium dark:text-white">
                        {props.title}
                    </div>
                    <div className="text-gray-600 dark:text-gray-200 text-sm">
                        {props.description}
                    </div>
                </div>
                <Link to={props.link} className="w-24 text-right flex justify-end">
                    <IoMdArrowRoundForward className="text-xl text-gray-900" />
                </Link>
            </div>
        </li>
    )

}

