import React from "react";

export default function Td(props) {
    return (
        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <div className={`${props.cls ? props.cls :"text-gray-900"}  whitespace-no-wrap`}>
                {props.children}
            </div>
        </td>
    );
};