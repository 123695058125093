import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Loading from '../../Loading';


Modal.setAppElement("#root");


export default function UpdateModal(props) {
    const [name, setName] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState()

    function toggleModal() {
        setIsOpen(!isOpen);
    }


    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token")).token
        setToken(token)
    }, [])

    const handleSubmit = (e) => {
        console.log("submit");
        e.preventDefault();
        update()
        toggleModal();
    };
    async function update() {
        setIsLoading(true);
        let item = { name };
        let result = await fetch(`https://likya.api.polyline.works/updateCategory/${props.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'x-access-token': token,
            },
            body: JSON.stringify(item)
        });
        result = await result.json();
        JSON.stringify(result)
        setIsLoading(false);
    }
    //* wtf nasıl oldu  o🧙‍♂️🧝‍♂️ ⚖ ben cikiyim 
    // sağol abi bende şu bebeleri yolliyim evden

    useEffect(() => {
        fetch(`https://likya.api.polyline.works/getCategory/${props.id}`)
            .then(response => response.json())
            .then(data => {
                setName(data?.name)
                console.log('dataaaa', data)
            })
    }, [props.id]);
    return (
        <React.Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <div>
                    <button className={props.class} onClick={toggleModal}>{props.title}</button>
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={toggleModal}
                        style={{
                            content: {
                                background: 'transparent',
                                outline: 'none',
                                border: 'transparent',
                                top: 2,
                                overflow: 'hidden'
                            },
                        }}
                    >
                        <button onClick={toggleModal} className="text-red-900 float-right" >
                            <AiOutlineCloseCircle className="w-6 h-6 text-red-900" />
                        </button>
                        <div className="relative  flex  justify-center   px-4 sm:px-6 lg:px-8">
                            <div className="absolute  opacity-60 inset-0 z-0">
                            </div>
                            <div className="sm:max-w-lg w-full p-10 bg-white rounded-xl z-10">
                                <div className="text-center">
                                    <h2 className="mt-5 text-3xl font-bold text-gray-900">
                                        Kategori Düzenleme
                                    </h2>
                                </div>
                                <form className=" mb-2 space-y-3" onSubmit={handleSubmit} >
                                    <div className="grid grid-cols-1 space-y-2">
                                        <label className="text-sm font-bold text-gray-500 tracking-wide">Kategori Adı</label>
                                        <input className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="kategori adı"
                                            name="name" defaultValue={name} onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <button type="submit" className="my-5 w-full flex justify-center bg-blue-500 text-gray-100 p-4  rounded-full tracking-wide
                                    font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-600 shadow-lg cursor-pointer transition ease-in duration-300">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </React.Fragment>
    );
}
