import React, { useState, useEffect } from 'react';
import Loading from '../../../Loading';
import Modal from "react-modal";
import { AiOutlineCloudUpload, AiOutlineCloseCircle } from "react-icons/ai";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default function AddModal(props) {
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image] = useState('');
    const [thumbnail] = useState('');
    const [previewSource, setPreviewSource] = useState('');
    const [thumbnailSource, setThumbnailSource] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState()
    const [order, setOrder] = useState();



    function toggleModal() {
        setIsOpen(!isOpen);
    }
    const handelFileInput = (e) => {
        const file = e.target.files[0];
        previewFile(file);
    }
    const handelFileInputs = (e) => {
        const file = e.target.files[0];
        thumbnailS(file);
    }
    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setPreviewSource(reader.result);
        }
    }
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token")).token
        setToken(token)
    }, [])
    const thumbnailS = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setThumbnailSource(reader.result);
        }
    }
    const handleSubmit = (e) => {
        console.log("submit");
        e.preventDefault();
        if (!previewSource) return;
        uploadImage(previewSource, thumbnailSource)
        toggleModal();
    }

    const uploadImage = async (image, thumbnail) => {
        setIsLoading(true);
        let item = { image, thumbnail, order, title, description, subTitle };
        await fetch("https://likya.api.polyline.works/createEvent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-access-token': token,
            },
            body: JSON.stringify(item)
        })
        console.log(item);
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            {
                isLoading ? (
                    <Loading />
                ) : (
                    <div className="flex items-center justify-center w-full">
                        <button className={props.class} onClick={toggleModal}>{props.title}</button>
                        <Modal
                            isOpen={isOpen}
                            onRequestClose={toggleModal}
                        >
                            <button onClick={toggleModal} className="float-right text-red-900" >
                                <AiOutlineCloseCircle className="w-6 h-6 text-red-900" />
                            </button>
                            <div className="flex justify-center w-full ">
                                {/* Fuck the world, think about your country  */}
                                <div className="">
                                    <div className="text-center">
                                        <h2 className="mt-5 text-3xl font-bold text-gray-900">
                                            Etkinlik Ekleme
                                        </h2>
                                        <div className="flex justify-center mt-6 ">
                                            <span className="p-2 mx-3 border-2 border-green-600">
                                                <p className="pb-1 text-purple-700">İmage</p>
                                                {previewSource && (<img src={previewSource} alt="chosen" style={{ height: '100px' }} />)}
                                            </span>
                                            <span className="p-2 mx-3 border-2 border-green-600">
                                                <p className="pb-1 text-purple-700">Thumbnail</p>
                                                {thumbnailSource && (<img src={thumbnailSource} alt="chosen" style={{ height: '100px' }} />)}
                                            </span>
                                        </div>
                                    </div>
                                    <form className="mt-8 space-y-3" onSubmit={handleSubmit} >
                                        <div className="grid grid-cols-1 space-y-2">
                                            <label className="text-sm font-bold tracking-wide text-gray-500">Resim</label>
                                            <div className="flex items-center justify-center w-full">
                                                <label className="flex flex-col w-full p-10 text-center border-4 border-dashed rounded-lg h-60 group">
                                                    <div className="flex flex-col items-center justify-center w-full h-full text-center ">
                                                        <div className="flex justify-center flex-auto w-2/5 mx-auto -mt-10 max-h-48">
                                                            <AiOutlineCloudUpload className="object-center w-1/2 h-36" />
                                                        </div>
                                                        <p className="font-bold text-gray-500 pointer-none "> 360 X 256</p>

                                                        <p className="text-gray-500 pointer-none ">(image) Tıklayınız...</p>
                                                    </div>
                                                    <input type="file" className="hidden"
                                                        name="image" value={image}
                                                        onChange={handelFileInput}
                                                    />
                                                </label>
                                                <label className="flex flex-col w-full p-10 mx-4 text-center border-4 border-dashed rounded-lg h-60 group">
                                                    <div className="flex flex-col items-center justify-center w-full h-full text-center ">
                                                        <div className="flex justify-center flex-auto w-2/5 mx-auto -mt-10 max-h-48">
                                                            <AiOutlineCloudUpload className="object-center w-1/2 h-36" />
                                                        </div>
                                                        <p className="font-bold text-gray-500 pointer-none "> 197 X 150</p>

                                                        <p className="text-gray-500 pointer-none ">(thumbnail) Tıklayınız...</p>
                                                    </div>
                                                    <input type="file" className="hidden"
                                                        name="thumbnail" value={thumbnail}
                                                        onChange={handelFileInputs} multiple
                                                    />
                                                </label>
                                            </div>
                                            <p className="text-sm text-gray-300">
                                                <span>File type: png,jpg,jpeg</span>
                                            </p>
                                        </div>
                                        <div className="grid grid-cols-1 space-y-2">
                                            <label className="text-sm font-bold tracking-wide text-gray-500">Başlık</label>
                                            <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="Blog Başlığı"
                                                name="title" value={title} onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 space-y-2">
                                            <label className="text-sm font-bold tracking-wide text-gray-500">Alt Başlık</label>
                                            <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="Alt Blog Başlığı"
                                                name="subTitle" value={subTitle} onChange={(e) => setSubTitle(e.target.value)}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 space-y-2">
                                            <label className="text-sm font-bold tracking-wide text-gray-500">Sırası</label>
                                            <input className="p-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="Sırası"
                                                name="subTitle" value={order} onChange={(e) => setOrder(Number(e.target.value))}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 space-y-2">
                                            <label className="text-sm font-bold tracking-wide text-gray-500">Detay</label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data=""
                                                name="description"
                                                value={description}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setDescription(data);
                                                }}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 space-y-2">
                                            <button type="submit" className="flex justify-center w-full p-4 my-5 font-semibold tracking-wide text-gray-100 transition duration-300 ease-in bg-blue-500 rounded-full shadow-lg cursor-pointer focus:outline-none focus:shadow-outline hover:bg-blue-600">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    </div>
                )
            }
        </React.Fragment>
    )
}