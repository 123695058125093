import React, { useEffect, useState } from "react";
import Head from "./partials/Head";
import Td from "./partials/Td";
import { IoAddCircleOutline, IoRefreshCircleOutline } from "react-icons/io5";
import { useQuery } from "react-query";
import * as dayjs from 'dayjs'
import 'dayjs/locale/tr'
import AddModal from "./partials/AddModal";
import UpdateModal from "./partials/UpdateModal";
import DeleteModal from "./partials/DeleteModal";
import Loading from '../../Loading';
dayjs.locale('tr')




export default function Faq() {
    const [data, setData] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        async function fetchFaq() {
            setIsLoading(true)
            const result = await fetch('https://likya.api.polyline.works/getFaqs')
            const response = await result.json()
            setData(response)
            setIsLoading(false)
        }
        fetchFaq()
    }, [refreshKey])


    const veri = (data) => {
        if (data.length === 0) {
            return <div>Data Yok</div>;
        }
        return data.map((faq, s) => {
            return (
                <tr key={faq.id}>
                    <Td>{s + 1}</Td>

                    <Td>{faq.question}</Td>
                    <Td cls="text-purple-600 font-bold">{faq.order}</Td>
                    <Td>{dayjs(faq.createDate).format("DD/MM/YYYY")}</Td>

                    <Td>
                        <UpdateModal title="Düzenle" class="text-indigo-600 hover:text-indigo-900" id={faq.id} />
                    </Td>
                    <Td>
                        <DeleteModal title="sil" name={faq.question} class="text-indigo-600 hover:text-indigo-900" id={faq.id} />
                    </Td>
                </tr>
            );
        });
    };
    return (
        <React.Fragment>
            {isLoading ? (<Loading />) : (
                <div className="flex justify-center items-center container ">
                    <div className="w-full">
                        <div className="py-8">
                            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto ">
                                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr>
                                                <Head>#</Head>
                                                <Head>Başlık</Head>
                                                <Head>Sıra</Head>
                                                <Head>Oluşturma Tarihi</Head>
                                                <Head>
                                                    <AddModal title={<IoAddCircleOutline className="w-6 h-6" />} class="py-1 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 h-8 rounded-lg " />
                                                </Head>
                                                <Head>
                                                    <button
                                                        onClick={() => setRefreshKey(oldKey => oldKey + 1)}
                                                        className="flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  h-8 rounded-lg "
                                                    >
                                                        <IoRefreshCircleOutline className="w-6 h-6" />
                                                    </button>
                                                </Head>
                                            </tr>
                                        </thead>
                                        <tbody>{veri(data)}</tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>

    );
}