import React from "react";

export default function ImageTd(props) {
    return (
        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <div className="flex items-center">
                <div className="flex-shrink-0">
                    {props.children}
                </div>
            </div>
        </td>
    );
};