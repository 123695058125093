import React, { useEffect, useState } from 'react';
import Table from './partials/table';
import Export from './partials/export';

export default function Bulten() {
    const [data, setData] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        async function fetchStage() {
            setIsLoading(true)
            const result = await fetch('https://likya.api.polyline.works/getBultens')
            const response = await result.json()
            setData(response || response)
            console.log("🌵💜🐢response", response)

            setIsLoading(false)
        }
        fetchStage()
    }, [refreshKey])

    return (
        <React.Fragment>
            {isLoading ? (<h1>asd</h1>) : (
                <div className='py-3 my-12 '>
                    <Export csvData={data} fileName="deneme" />
                    <Table className="mx-auto" data={data} bulten />
                </div>
            )}
        </React.Fragment>
    );
}