import React, { useState, useEffect } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";


export default function Control(props) {
    const [data, setData] = useState('');
    const [link, setLink] = useState(props.id)
    const [thumbnail, setThumbnail] = useState(props.thumbnail)
    const [title, setTitle] = useState(props.title)
    const [token, setToken] = useState()

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token")).token
        setToken(token);
        fetch(`https://likya.api.polyline.works/getDbVideos/${props.id}`)
            .then(response => response.json())
            .then(json => setData(json));
    }, [props.id]);
    function refreshPage() {
        window.location.reload(false);
    }
    const postVideos = async () => {
        let item = { link, thumbnail, title }
        await fetch(`https://likya.api.polyline.works/createVideos`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-access-token': token,

            },
            body: JSON.stringify(item)
        })
        console.log(link, "eklendi");
        refreshPage()
    }
    let video = props.id;
    let thumbnails = props.thumbnail
    const deleteVideo = async () => {
        await fetch(`https://likya.api.polyline.works/deleteVideo/${data.id}`)
        console.log("🌵💜🐢 silindi", data.id)
        refreshPage()
    }
    return (
        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200"  >
            <div className="flex">
                {data ? <button onClick={deleteVideo}><AiOutlineMinusCircle className="inline ml-3 text-xl text-purple-600" /></button> : null}
                <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                    {video ? <div>
                        <span className={`absolute inset-0 ${data ? "bg-green-200" : "bg-red-200"}  opacity-50 rounded-full`}>
                        </span>
                        <div className="relative">
                            <span className="flex justify-center">
                                {data ? "Eklendi" : "Eklenmedi"}
                            </span>
                        </div>
                    </div> : null}
                </span>
                {video ? <button type="submit" onClick={postVideos} value={video} onChange={(e) => { setLink(e.target.value); setThumbnail(thumbnails); setTitle(title) }}>{data ? "" : <AiOutlinePlusCircle className="inline ml-3 text-xl text-purple-600" />}</button> : null}

            </div>
        </td>
    )
}

