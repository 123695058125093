import React from 'react';
import ReactDOM from 'react-dom';
import 'tailwindcss/tailwind.css'
import GenelRouter from "./routers/GenelRouter";
import './styles/editor.css'




ReactDOM.render(<GenelRouter />, document.getElementById("root"));

