import React from "react";

export default function Td(props) {
    return (
        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                <span aria-hidden="true" className={` absolute inset-0 opacity-50 rounded-full ${props.status  ? "bg-green-200" : "bg-red-200"}`}>
                </span>
                <span className="relative">
                    {props.status ? "Published" : "Unpublished"}
                </span>
            </span>
        </td>
    );
};