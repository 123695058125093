import React, { useEffect, Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar"

export const Private = ({ component: Component, ...rest }) => {
    useEffect(() => { }, [Component]);
    const token = localStorage.getItem("user-token");
    if (!token) {
        return <Redirect to="/" />;
    }
    return (
        <Fragment >
            <Navbar />
            <div className=" container mx-auto">
                <Route className=""
                    {...rest}
                    component={(props) => {
                        return <Component token={token} {...props} />;
                    }}
                />
            </div>
        </Fragment>
    );
};

export default Private;