import React, { useState, useEffect } from 'react';
import Loading from '../../../Loading';
import Modal from "react-modal";
import { AiOutlineCloudUpload, AiOutlineCloseCircle } from "react-icons/ai";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'


Modal.setAppElement("#root");


export default function UpdateModal(props) {
    const [order, setOrder] = useState(0);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [image] = useState('');
    const [previewSource, setPreviewSource] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState()

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token")).token
        setToken(token)
    }, [])

    function toggleModal() {
        setIsOpen(!isOpen);
    }
    const handelFileInput = (e) => {
        const file = e.target.files[0];
        previewFile(file);
    }

    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setPreviewSource(reader.result);
        }
    }


    const handleSubmit = (e) => {
        console.log("submit");
        e.preventDefault();
        if (!previewSource) return;
        update(previewSource)
        toggleModal();
    }

    async function update(image) {
        setIsLoading(true);
        let item = { image, order, title, subTitle };
        let result = await fetch(`https://likya.api.polyline.works/updateGallery/${props.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'x-access-token': token,

            },
            body: JSON.stringify(item)
        });
        result = await result.json();
        JSON.stringify(result)
        setIsLoading(false);
    }

    useEffect(() => {
        fetch(`https://likya.api.polyline.works/getGallery/${props.id}`)
            .then(response => response.json())
            .then(data => {
                setOrder(data?.order)
                setTitle(data?.title)
                setSubTitle(data?.subTitle)
                setPreviewSource(data?.image)
            })
    }, [props.id]);

    return (
        <React.Fragment>
            {
                isLoading ? (
                    <Loading />
                ) : (
                    <div className="flex justify-center items-center w-full">
                        <button className={props.class} onClick={toggleModal}>{props.title}</button>
                        <Modal
                            isOpen={isOpen}
                            onRequestClose={toggleModal}
                            style={{
                                content: {
                                },
                            }}
                        >
                            <button onClick={toggleModal} className="text-red-900 float-right" >
                                <AiOutlineCloseCircle className="w-6 h-6 text-red-900" />
                            </button>
                            <div className="flex  justify-center  w-full ">
                                {/* Fuck the world, think about your country  */}
                                <div className="">
                                    <div className="text-center">
                                        <h2 className="mt-5   text-3xl font-bold text-gray-900">
                                            Etap ve Günce Ekleme
                                        </h2>
                                        <div className=" justify-center flex mt-6 ">
                                            <span className="border-2 border-green-600 mx-3 p-2">
                                                <p className="pb-1 text-purple-700">İmage</p>
                                                {previewSource && (<img src={previewSource} alt="upadte" className="w-48 h-48" />)}
                                            </span>

                                        </div>
                                    </div>
                                    <form className="mt-8 space-y-3" onSubmit={handleSubmit} >
                                        <div className="grid grid-cols-1 space-y-2">
                                            <label className="text-sm font-bold text-gray-500 tracking-wide">Resim</label>
                                            <div className="flex items-center justify-center w-full">
                                                <label className="flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group text-center">
                                                    <div className="h-full w-full text-center flex flex-col  justify-center items-center  ">
                                                        <div className="flex flex-auto max-h-48 w-2/5 mx-auto -mt-10 justify-center">
                                                            <AiOutlineCloudUpload className="w-1/2 h-36 object-center" />
                                                        </div>
                                                        <p className="pointer-none text-gray-500 font-bold "> 288 X 190</p>
                                                        <p className="pointer-none text-gray-500 ">(image) Tıklayınız...</p>
                                                    </div>
                                                    <input type="file" className="hidden"
                                                        name="image" value={image}
                                                        onChange={handelFileInput}
                                                    />
                                                </label>

                                            </div>
                                            <p className="text-sm text-gray-300">
                                                <span>File type: png,jpg,jpeg</span>
                                            </p>
                                        </div>
                                        <div className="flex flex-col justify-center  items-center">
                                            <span className="flex flex-col px-2 w-full">
                                                <label className="text-sm font-bold text-gray-500 tracking-wide">Başlık</label>
                                                <input className="text-base   p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="Başlığı"
                                                    name="title" value={title} onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </span>
                                            <span className="flex flex-col px-2 w-full">
                                                <label className="text-sm font-bold text-gray-500 tracking-wide">Alt Başlık</label>
                                                <input className="text-base   p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="text" placeholder="Alt Başlığı"
                                                    name="subTitle" value={subTitle} onChange={(e) => setSubTitle(e.target.value)}
                                                />
                                            </span>
                                            <span className=" flex flex-col px-2 w-full" >
                                                <label className="text-sm font-bold text-gray-500 tracking-wide">Sıra</label>
                                                <input className="text-base  p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="number" placeholder="Sıra"
                                                    name="order" value={order} onChange={(e) => setOrder(parseInt(e.target.value))}
                                                />
                                            </span>

                                        </div>
                                        <div className="grid grid-cols-1 space-y-2">
                                            <button type="submit" className="my-5 w-full flex justify-center bg-blue-500 text-gray-100 p-4  rounded-full tracking-wide
                        font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-600 shadow-lg cursor-pointer transition ease-in duration-300">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    </div>
                )
            }
        </React.Fragment>
    );
}
