import { Link } from "react-router-dom";

import Li from './li';

export default function Settings() {

    return (
        <>
            <div className="container flex flex-col mx-auto  my-24  w-1/2 items-center justify-center">
      <div className="px-4 py-5 sm:px-6 w-full border dark:bg-gray-800 bg-white shadow mb-2 rounded-md">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
          Ayarlar 
        </h3>
      </div>
      <ul className="flex flex-col w-full">
        {/* page -- */}
        <Li title="Bülten"  link="/bulten" />
        <Li title="İletisim" link="/iletisim" />
        <Li title="Yorumlar" link="/yorumlar" />
      </ul>
    </div>
        </>
    )
}